<template>
  <div class="trusteeship_item">
    <!-- <router-link :to="{ name: 'trusteeship_detail', params: { id: 1 } }"> -->
    <router-link
      :to="{
        name: 'trusteeship_detail',
        params: { id: info.contract.contractId },
      }"
    >
      <div class="status">托管成功</div>
      <div class="info">
        <div class="image">
          <img
            v-if="info.villageInfo.images && info.villageInfo.images.length > 0"
            :src="info.villageInfo.images[0].url"
          />
          <img
            v-else
            :src="pkey >= images.length ? images[pkey - images.length] : images[pkey]"
            alt=""
          />
        </div>
        <div class="title" :title="info.companyName">
          {{ info.villageInfo.organizationName || "" }}
          <!--{{ info.village.countryName }}{{ info.village.villageName }}-->
        </div>
        <p>托管面积：{{ info.contract ? info.contract.area : 0 }}亩</p>
        <!--<p>
          托管方：{{ info.villageWebInfo.organizationName }}
        </p>-->
        <!-- <p>托管类型：水稻</p> -->
      </div>
    </router-link>
  </div>
</template>

<script type="text/ecmascript-6">
import case0 from "@/assets/images/case0.jpg";
import case1 from "@/assets/images/case1.jpg";
import case2 from "@/assets/images/case2.jpg";
import case3 from "@/assets/images/case3.jpg";
import case4 from "@/assets/images/case4.jpg";
import case5 from "@/assets/images/case5.jpg";
import case6 from "@/assets/images/case6.jpg";
import case7 from "@/assets/images/case7.jpg";
import case8 from "@/assets/images/case8.jpg";
import case9 from "@/assets/images/case9.jpg";
import case10 from "@/assets/images/case10.jpg";
import case11 from "@/assets/images/case11.jpg";
import case12 from "@/assets/images/case12.jpg";
import case13 from "@/assets/images/case13.jpg";
import case14 from "@/assets/images/case14.jpg";
import case15 from "@/assets/images/case15.jpg";
import case16 from "@/assets/images/case16.jpg";
import case17 from "@/assets/images/case17.jpg";
import case18 from "@/assets/images/case18.jpg";
import case19 from "@/assets/images/case19.jpg";
import case20 from "@/assets/images/case20.jpg";
import case21 from "@/assets/images/case21.jpg";
import case22 from "@/assets/images/case22.jpg";
import case23 from "@/assets/images/case23.jpg";
import case24 from "@/assets/images/case24.jpg";
import case25 from "@/assets/images/case25.jpg";
import case26 from "@/assets/images/case26.jpg";
import case27 from "@/assets/images/case27.jpg";
import case28 from "@/assets/images/case28.jpg";
import case29 from "@/assets/images/case29.jpg";
import case30 from "@/assets/images/case30.jpg";
export default {
  data() {
    return {
      images: [
        case0,
        case1,
        case2,
        case3,
        case4,
        case5,
        case6,
        case7,
        case8,
        case9,
        case10,
        case11,
        case12,
        case13,
        case14,
        case15,
        case16,
        case17,
        case18,
        case19,
        case20,
        case21,
        case22,
        case23,
        case24,
        case25,
        case26,
        case27,
        case28,
        case29,
        case30,
      ],
    };
  },
  props: {
    info: {
      type: Object,
    },
    pkey: {
      type: Number,
      default: 0,
    },
  },
  created() {},
  components: {},
};
</script>

<style scoped lang="scss">
.trusteeship_item {
  flex-basis: 25%;
  position: relative;
  padding-left: 30px;
  // width: 100%;
  width: 25%;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 15px 0;
  &:nth-child(4n + 1) {
    padding-left: 15px;
    .status {
      left: 0px;
    }
  }
  a {
    text-decoration: none;
    display: block;
    padding-bottom: 15px;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid #008840;
    }
  }
  img {
    width: auto;
  }
  .status {
    position: absolute;
    top: 20%;
    left: 15px;
    background: url("~@/assets/images/bg.png");
    color: white;
    line-height: 52px;
    padding: 0 15px;
  }
  .info {
    .image {
      height: 180px;
      overflow: hidden;
      img {
        height: 180px;
      }
    }
    .title {
      color: #333;
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 2.3em;
      text-indent: 10px;
    }
    p {
      text-indent: 10px;
      color: #9e9e9e;
      font-size: 12px;
      margin: 0;
    }
  }
}
</style>
