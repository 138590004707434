<template>
  <div class="trusteeships_index">
    <a-spin :spinning="loading">
      <div class="list">
        <template v-for="(item, index) in list">
          <trusteeship v-if="item.contract" :info="item" :key="index" :pkey="index" />
        </template>
        <custom-page @change="changePage" :total="total" :current="pageNum"></custom-page>
      </div>
    </a-spin>
  </div>
</template>

<script type="text/ecmascript-6">
import Trusteeship from "@/components/Trusteeship";
import Api from "@/api/project";
export default {
  data() {
    return {
      pageNum: 1,
      pageSize: 12,
      total: 0,
      list: [],
      loading: false,
    };
  },
  components: { Trusteeship },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      Api.list({ pageNum: this.pageNum, pageSize: this.pageSize })
        .then((res) => {
          this.total = res.result.total;
          this.list = res.result.records;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    changePage(page) {
      this.pageNum = page;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.trusteeships_index {
  .list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    box-sizing: border-box;
  }
}
</style>
